<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Region Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input
          class="w-full mt-4"
          label="Name"
          :value="form.name"
          @input="updateForm({key: 'name', value: $event})"/>
        <span class="text-danger text-sm"
          v-show="validation.name?true:false">
          {{ validation.name?validation.name[0]:''}}
        </span>

        <vs-input
          class="w-full mt-4"
          label="Slug"
          :danger="validation.slug?true:false"
          :danger-text="validation.slug?validation.slug[0]:''"
          :value="form.slug"
          @input="updateForm({key: 'slug', value: $event})"/>

        <vs-checkbox
          class="w-full mt-4"
          :value="form.display_home"
          @input="updateForm({key: 'display_home', value: $event})">
          Display Home
        </vs-checkbox>

        <vs-checkbox
          class="w-full mt-4"
          :value="form.status"
          @input="updateForm({key: 'status', value: $event})">
          Status
        </vs-checkbox>

        <vs-textarea
          label="Description"
          class="w-full mt-4"
          :value="form.description"
          @input="updateForm({key: 'description', value: $event})"/>
        <span class="text-danger text-sm"
          v-show="validation.description?true:false">
          {{ validation.description?validation.description[0]:''}}
        </span>
      </div>

    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    vSelect,
  },
  computed: {
    ...mapGetters({
        form: 'homestay_region/getFormObj',
        validation: 'validation/getValidationErrors'
    }),
  },
  methods: {
    ...mapActions('homestay_region', [
      'updateForm'
    ]),
    ...mapMutations({
      CLEAR_FORM: 'homestay_region/CLEAR_FORM'
    }),
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
