<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
        <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <RegionForm></RegionForm>
          </div>
          <div class="vx-card p-6 mb-6">
              <SeoInfo></SeoInfo>
            </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <div class="vx-card p-6 mb-6">
              <AdditionInfo></AdditionInfo>
            </div>
        </div>
       </div>
            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form>
    </div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import RegionForm from './common/RegionForm';
import AdditionInfo from './common/AdditionInfo';
import SeoInfo from './common/SeoInfo';
import {HOMESTAY_REGION} from '../../../constant/entity-identifier'
import { mapGetters, mapActions } from 'vuex'
import {updateImage, storeImage} from '../../../services/image';
import loaderMixin from '../../../mixins/loaderMixin';

export default {
  components: {
    vSelect,
    RegionForm,
    AdditionInfo,
    SeoInfo
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'homestay_region/getFormObj'
    }),
    homestayRegionId() {
        return this.$route.params.homestayRegionId;
    },
  },
  created() {
    this.openLoading();
    this.fetchAndSetHomestayRegionById(this.homestayRegionId).then((res) => this.closeLoading())
      .catch((e) => {
        this.closeLoading();
        this.$router.push('/homestay-regions');
        });
  },
  methods: {
    ...mapActions('homestay_region', [
        'updateHomestayRegionAction',
        'fetchAndSetHomestayRegionById'
    ]),
    prepareForm(data, type, method) {
      let formData = new FormData();
      formData.append("_method", method);
      formData.append("entity_id", data.id);
      formData.append("entity", type);
      for (let i = 0; i < this.form.images.length; i++) {
          formData.append("images[" + i + "]", this.formData.images[i]);
          formData.append("alt[" + i + "]", 'alt');
      }
      return formData;
    },
    submitForm() {
      this.openLoading();
      this.updateHomestayRegionAction({
        HomestayRegionId: this.HomestayRegionId,
        data: this.form
      }).then(async response => {
           if(response.status === 202) {
             if(this.form.images.length > 0) {
               if(response.data.data.images){
                    await updateImage(response.data.data.images[0].id, this.prepareForm(response.data.data, HOMESTAY_REGION, 'POST'));
                }else{
                    await storeImage(this.prepareForm(response.data.data, HOMESTAY_REGION, 'POST'));
                }
               this.closeLoading()
             }else{
               this.closeLoading();
             }  
            this.$vs.notify({ 
                text: 'Homestay region updated successfully.',
                title: 'Homestay Region Updated',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push('/homestay-regions');
           }
        }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
